import { ItemTypes, DefaultImageMetrics } from "../Consts";
import { TextEditor } from "./Text/TextEditor";
import React, { useState, useEffect, useRef } from "react"
import "bootstrap/dist/css/bootstrap.css"
import './piece.css'
import ImageEditor from './ImageEditor';
import { useDrag } from 'react-dnd'
import AuthorName from "../Images/AuthorName";

export const Piece = (item,) => {
console.log("VIEW ONLY:" + item.viewOnlyMode);


    const left = item.left
    const top = item.top
    const [isDragging, drag] = useDrag(() => ({
        //type: ItemTypes.TEXT_BOX,
        type: item.type,
        item: item,
        collect: monitor => ({
          isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
          const didDrop = monitor.didDrop()
          console.log("drop result:"+didDrop)
        }
      }), [item.id,item.left,item.top, item.content]);

  const uploadImageActions = (imgName, image_id, width, height, rotate,scale,border) =>
  {
    console.log("attempting to update image. image_id:"+image_id+", width:"+width+" ,height:"+height+" ,rotate:"+rotate)
    item.onImageUploadActions(item.imageName, image_id, width, height, rotate,scale,border)
  }

  const uploadImageDoneActions = () =>
  {
    item.onImageUploadDone(item.imageName)
  }
  const onImgPrivateChange = (imgID, isPrivate, desc) =>
  {
    item.onImgPrivateChange(imgID, isPrivate,desc)
  }

  //const uploadTextDone = (textFileUrl) =>
  const uploadTextDone = (htmlContent, textbox_width, text_left) =>
  {
    //console.log("bubbeling uploaded text file url to parent. url="+textFileUrl+"id="+item.id)
    console.log("bubbeling uploaded text html content to parent. text="+htmlContent+"id="+item.id)
    item.onUploadTextDone(htmlContent, item.id, textbox_width,text_left)
    //item.onImageUploadDone('item.imageName')
  }

  return  (
    <div id="pieceWrapper"
          /*ref={drag}*/
          style={{ left, top }}
          role="Box"
          className= "box"
          >
            {item.type == ItemTypes.IMAGE ?
              <>
              <ImageEditor 
              img_url={item.content}
              img_name={item.imageName}
              crossOrigin="anonymous"
              is_shown={item.removeImg}
              img_id={item.id}
              onImageUploadActions = {uploadImageActions}
              onImageUploadDone = {uploadImageDoneActions}
              onImgPrivateChange = {onImgPrivateChange}
              getImgUrl = {item.getImgUrl}
              isPrivate = {item.isPrivate}
              border = {item.border==undefined ? 0 : item.border}
              read_only = {isDragging.isDragging || item.viewOnlyMode  }
              className = "image_piece"
              width={item.width}
              height={item.height}
              rotate={item.rotate}
              scale={item.scale}
              handlePencilClick={item.handlePencilClick}
              private_image = {item.private_image}
              /> 
              {item.author && item.author.split(' ').length > 1 && 
              (<AuthorName firstName={item.author.split(' ')[0]} lastName={item.author.split(' ')[1]} 
              />
)}
              </>
            : item.type == ItemTypes.TEXT_BOX ?
              <TextEditor
              is_shown = {item.removeImg}
              txt_id = {item.id}
              onUploadTextDone = {uploadTextDone}
              html_content = {item.content}
              read_only = {isDragging.isDragging || item.viewOnlyMode}
              className = "text_piece"
              onEditModeChange = {item.onEditModeChange}
              width={item.width}
              left={item.left}
              />
              :
              /*<PlainImage 
                symbol={item.content}
                is_shown = {item.removeImg}
                className = "symbol_piece"
                />*/
                <ImageEditor 
                  img_url={item.content.src}
                  img_name={item.imageName}
                  crossOrigin="anonymous"
                  is_shown={item.removeImg}
                  img_id={item.id}
                  onImageUploadActions = {uploadImageActions}
                  onImageUploadDone = {uploadImageDoneActions}
                  getImgUrl = {item.content.src}
                  isPrivate = {false}
                  read_only = {isDragging.isDragging || item.viewOnlyMode}
                  className = "symbol_piece"
                  width={item.width ? item.width : DefaultImageMetrics.SYMBOL_WIDTH}
                  height={item.height ? item.height : DefaultImageMetrics.SYMBOL_HEIGHT}
                    rotate={item.rotate}
                    scale={item.scale}
                    border = {item.border==undefined? 4:item.border}
                /> 
                }
      </div>
  )
}