export const ItemTypes = {
    IMAGE_ICON: 'img-icon',
    TEXT_ICON: 'txt-icon',
    IMAGE: 'img',
    TEXT_BOX: 'txt',
    SYMBOL:'symbol'
  }

  export const BoardMetrics = {
    CELLS_X: 20,
    CELLS_Y: 20,
    SQUARE_SIZE: '5%',
    BOARD_HIEGHT:640
  }

  export const DefaultImageMetrics = 
  {
    LEFT:10,
    TOP: 10,
    SYMBOL_WIDTH:90,
    SYMBOL_HEIGHT:90,
    DISTANCE_BETWEEN_BOXES:5,
    IMAGE_HEIGHT:150,
    IMAGE_HEIGHT_RESIZE:300,
    TEXT_BOX_HEIGHT:90,
    BACKGROUND_IMAGE_HEIGHT:640,
    THEME_IMAGE_HEIGHT:200,
  }

  export const DefaultTextBoxMetrics =
  {
    TEXT_BOX_DISTANCE_FROM_LEFT:30,
    TEXT_BOX_DISTANCE_FROM_LEFT_RTL: 30,
  }

  export const Locations = {
    IMAGE_FOLDER: 'images/',
    TEXT_FOLDER: 'texts/',
    PDF_FOLDER: 'pages-as-pdf',
    STUDENT_IMAGE_FOLDER: 'student-images',
    USERS_TABLE: 'users',
    BOOKS_TABLE: 'books',
    LEADS_TABLE: 'leads',
    BOOKS_TABLE_PAGES_FIELD: 'pages',
    USERS_TABLE_STUDENT_FIELD: 'students',
    ORGANIZATIONS_TABLE: 'organizations',
    STUDENTS_TABLE: 'students',
    PAYMENTS_TABLE: 'payments'

    //PAGES_TABLE: 'pages'
  }

  export const AccountTypes = {
    CONTRIBUTOR: 'contributor',
    ORGANIZATION: 'organization',
    PRIVATE: 'private'
  }

  export const LocalStorageKeys = {
    userKey: 'user',
    USER_METADATA: 'user-metadata',
    USER_GROUP_NAME:'user-group-name',
    last_visited_page: 'last_visited_page',
    current_book_id: 'current_book_id',
    stories_per_students: 'stories_per_students',
    book_page_id: 'book_page_id',
    new_user: 'newUser',
    organizations:'organizations',
    has_free_generation:'free_generation_quota'
  }

  export const EmailService ={
    SERVICE_ID:'service_8o5z345',
    TEMPLATE_ID:'template_kbywe6t',
    USER_ID:'EUBGuwFSXWfKMlBfR',
  }

  export const BookPermissions={
    PRIVATE: 'private',
    PUBLIC: 'public',
    ORG_PUBLIC: 'org-public',
  }

  export const UserPermission={
    OZZY_PUBLISHER: 'ozzy-publisher',
    ORG_ADMIN:'org-admin',
    ORG_GROUP_ADMIN:'group-admin',
    ORG_USER:'org-user',
    MAX_STUDENT:1,
    SUBSCRIBER_MAX_STUDENTS:3,
  }
