import React, { useState, useEffect } from "react";
import firebase, { auth } from "../firebase";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import {GoogleAuthProvider, EmailAuthProvider} from '@firebase/auth';
import "./login.css";
import { t } from 'i18next';
import { useTranslation } from "react-i18next";
import Form from 'react-bootstrap/Form'
import { ToastMessage } from "../BookEdit/ToastMessage";
import { useParams, Link } from "react-router-dom";
import { ValidateOrganizationLogin } from "../Organization/ValidateOrganizationLogin";
import {get_organization} from "../BookEdit/DBUtils"
 
export const LoginScreen = ({shouldRedirect, onLogin, postFirebaseLogin}) => {
  // Configure FirebaseUI.

  const [confirmedTerms, setConfirmTerms] = useState(false)
  const [showAcceptTermsAlert, setShowAcceptTermsAlert] = useState(false)
  const [showOrgPassValidation, setShowOrgPassValidation] = useState(false)
  const [authResult, setAuthResult] = useState(null)
  const [orgName, setOrgName] = useState("")
  const [orgLogo, setOrgLogo] = useState("")
  const params = useParams();
  const { i18n,t } = useTranslation();

  const onOrganizationValidated = () =>{
    setShowOrgPassValidation(false)
    if (authResult && params.orgid && params.groupid)
    {
      onLogin(authResult, params.orgid, params.groupid).then(()=>{
        window.location.href = '/search';
      })
    }
  }

  const setOrganizationName = async (org_id) =>{
    await get_organization(org_id).then(response=>{
      if (response.name.HE && response.name.EN)
      {
        let name = (i18n.language=='he') ? response.name.HE : response.name.EN
        setOrgName(name)
        if (response.logo) setOrgLogo(response.logo)
      }
    })
  }

  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    // signInSuccessUrl: shouldRedirect ? '/search' : null,
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      GoogleAuthProvider.PROVIDER_ID,
      EmailAuthProvider.PROVIDER_ID,
    ],

    tosUrl: '/terms',
    privacyPolicyUrl: '/privacy',

    callbacks: {
      signInSuccessWithAuthResult: function(authResult, redirectUrl) {
        // Process result. This will not trigger on merge conflicts.
        // On success redirect to signInSuccessUrl.
        console.log("sucessfull login. authResult="+JSON.stringify(authResult)+" redirectURL="+redirectUrl)
        //parent callback - display user details on UI
        let orgid = params.orgid
        let groupid = params.groupid
        setAuthResult(authResult)

        if (orgid!=null && groupid!=null){
          setShowOrgPassValidation(true)
          setOrganizationName(orgid)
        }
        else{
          onLogin(authResult, orgid, groupid).then(()=>{
            window.location.href = '/search';
          })
        }

        return false;
      },
      // signInFailure callback must be provided to handle merge conflicts which
      // occur when an existing credential is linked to an anonymous user.
      signInFailure: function(error) {
        // For merge conflicts, the error.code will be
        // 'firebaseui/anonymous-upgrade-merge-conflict'.
        if (error.code != 'firebaseui/anonymous-upgrade-merge-conflict') {
          return Promise.resolve();
        } 
      //}
      }//end of sign in failure 
    }//end of callbacks
  };

  const onLoginContainerClick = () =>{
    if (!confirmedTerms)
      setShowAcceptTermsAlert(true)
  }

  const onConfirmTerms = () => {
    setShowAcceptTermsAlert(false)
    setConfirmTerms(!confirmedTerms)
  }

  return (
    <div>
      <div style={{display:!showOrgPassValidation ? 'block' : 'none'}}>
        <p className="login_header">
          {shouldRedirect ? t('login_sign_in') : t('login_sign_in_popup')}
        </p>

        <div className="alertContainer">
          <div className="read_privacy_alert">
            {showAcceptTermsAlert ?
            t("login_approve_terms_alert")
            :
            null
            }</div>
        </div>

        {postFirebaseLogin ? (
            <ToastMessage
              header_text={t("login_toast_header")}
              body_text={t("login_toast_body")}
              position="top-center"
            />
          ) : null}

        <div onClick={onLoginContainerClick} className="loginContainer">
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
        </div>

        {/* Apply CSS class based on confirmedTerms */}
        {confirmedTerms || (
          <style>
            {`
              .firebaseui-idp-button {
                pointer-events: none;
                /*background-color: grey !important;*/
                opacity: 0.6;
              }
              .firebaseui-idp-google{
                background-color: #fff00;
              }
              .firebaseui-idp-password{
                background-color:#db4437000;
              }
              .firebaseui-idp-text{
                color: #b1b1b1;
              }
            `}
          </style>
        )}
        {showOrgPassValidation || postFirebaseLogin ? <div></div>: 
        <div className="checkbox-container">
          <Form.Group controlId="formBasicCheckbox">
            <div className="checkbox-wrapper">
              <Form.Check
                type="checkbox"
                label={
                  <>
                    {t('login_privacy_checkbox')}{<a className="login_terms_link" href='/privacy' target="_blank" rel="noopener noreferrer">
                    {t('login_privacy')}</a>}
                    {t('login_and')}
                      {<a className="login_terms_link" href='/terms' target="_blank" rel="noopener noreferrer">{t('login_terms')}</a>}
                  </>
                }
                defaultChecked={false}
                onChange={(event) => onConfirmTerms()}
                disabled = {confirmedTerms ? true : false}
              />
            </div>
          </Form.Group>
        </div>}
      </div>
        <div>
        {showOrgPassValidation ? 
        <ValidateOrganizationLogin
          orgId={params.orgid}
          onOrganizationValidated={onOrganizationValidated}
          orgName={orgName}
          orgLogo={orgLogo}
          authResult={authResult}
          >
        </ValidateOrganizationLogin>
        : null}
        </div>
    </div>
  );
}
//export default LoginScreen;
